import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import PeopleCard from "./peopleCard/PeopleCard";
import { fetchPerson } from "../../features/peopleSearch";
import { Link, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { usePageTitle } from "../../hooks/usePageTitle";

type Props = PropsFromRedux;

const PersonSearchResult = (props: Props) => {
  const { ucmnetid } = useParams();
  const { setPageTitle } = usePageTitle();

  const { fetchPerson, loading, error, person } = props;

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }

    // Side-effect...
    if (ucmnetid && !person && loading === "idle") {
      fetchPerson(ucmnetid);
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [person, error]);

  useEffect(() => {
    if (person) {
      setPageTitle(person.fullName);
    }
  }, [person]);

  return (
    <React.Fragment>
      <Link to="/">← Directory Home</Link>
      <div className="Results">
        {person && (
          <div className="Results-results">
            <PeopleCard
              key={person.ucmnetid}
              person={person}
              permaLinkSingleResult={true}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { person, loading, error } = state.people;
  return {
    person,
    loading,
    error,
  };
};

const mapDispatch = {
  fetchPerson,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(PersonSearchResult);
