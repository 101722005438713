import React, {useEffect, useRef, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";
import NonEditPersonalInfo from "../nonEditPersonalInfo.tsx/NonEditPersonalInfo";
import {setIsDirty, setShowDepartmentList} from "../../features/peopleSearch";
import {
  fetchAssistanTypes,
  fetchPhoto,
  fetchUserPreferences,
  PhotoUploadRequest,
  requestPhotoHeadshot,
  resetPhotoUpload,
  resetUserState,
  updateUserPreferences,
  uploadPhoto,
} from "../../features/userPreferences";
import {Link, useLocation, useParams} from "react-router-dom";
import personAvatar from "../Content/images/defaultImage.png";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import arrayMutators from "final-form-arrays";
import {Field, FieldRenderProps, Form, FormSpy} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import createDecorator from "final-form-focus";
import {UserDepartments, UserPreferencesExtended,} from "../../apis/dto/UserPreferences";
import "./PersonalInfo.scss";
import {Assistant} from "../../apis/dto/People";
import AssistantSearch from "../assistantSearch.tsx/AssistantSearch";
import {fetchBuildingsPersonnel} from "../../features/departments";
import UserAdminsOfDepartments from "../userAdminsOfDepartments/UserAdminsOfDepartments";
import {FormApi} from "final-form";
import {toastr} from "react-redux-toastr";
import {setIsSaving} from "../../features/globalStateReducer";
import {ErrorToastr} from "../utility/ErrorToastr";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {
  ButtonType,
  CharacterCounter,
  DisclosureContent,
  DisclosureProvider,
  DisclosureTrigger,
  MessageType
} from "@ucm-it/purrfect-components";
import {AboutGuidelines} from "../AboutGuidelines";

type Props = PropsFromRedux & {};

const focusOnErrors = createDecorator();

const PersonalInfo = (props: Props) => {
  const { ucmNetid } = useParams();
  const location = useLocation();
  const {
    fetchUserPreferences,
    updateUserPreferences,
    //setIsDirty,
    setShowDepartmentList,
    resetUserState,
    requestPhotoHeadshot,
    uploadPhoto,
    fetchPhoto,
    error,
    loading,
    selectUserPreferences,
    fetchAssistanTypes,
    AssistantTypesState,
    fetchBuildingsPersonnel,
    buildingsPersonnelState,
    authState,
    setIsSaving,
    resetPhotoUpload,
  } = props;

  const ucmnetid = ucmNetid ?? (authState.username as string);

  const isAdminPreferences = location.pathname.includes("/adminPreferences");

  const [isVisibleImageGuide, setIsVisibleImageGuide] = useState(false);
  const [isVisiblePhotoBox, setIsVisiblePhotoBox] = useState(true);

  const loadUserPreferences = async (ucmnetid: string) => {
    await fetchUserPreferences(ucmnetid);
  };

  const loadUserPhoto = async (userPreferneces: UserPreferencesExtended) => {
    await fetchPhoto(userPreferneces);
  };
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }
    if (buildingsPersonnelState.error) {
      const { title, message } = buildingsPersonnelState.error;
      toastr.error(title, message);
    }
    if (
      selectUserPreferences &&
      selectUserPreferences.photoHeadshotAppointment &&
      selectUserPreferences.photoHeadshotAppointment.error
    ) {
      const { title, message } =
        selectUserPreferences.photoHeadshotAppointment.error;
      toastr.error(title, message);
    }
    if (
      selectUserPreferences &&
      selectUserPreferences.uploadPhoto &&
      selectUserPreferences.uploadPhoto.error
    ) {
      const { title, message } = selectUserPreferences.uploadPhoto.error;
      toastr.error(title, message);
      resetPhotoUpload();
    }
    if (
      selectUserPreferences &&
      selectUserPreferences.photoLoading &&
      selectUserPreferences.photoLoading.error
    ) {
      const { title, message } = selectUserPreferences.photoLoading.error;
      toastr.error(title, message);
    }

    if (AssistantTypesState && AssistantTypesState.error) {
      const { title, message } = AssistantTypesState.error;
      toastr.error(title, message);
    }
    if (
      buildingsPersonnelState &&
      buildingsPersonnelState.entities &&
      buildingsPersonnelState.entities.length < 1 &&
      buildingsPersonnelState.loading === "idle"
    ) {
      fetchBuildingsPersonnel();
    }
    if (!selectUserPreferences && loading === "idle" && ucmnetid) {
      loadUserPreferences(ucmnetid);
      //await fetchUserPreferences(ucmnetid);
    }
    if (
      selectUserPreferences &&
      !selectUserPreferences.photo &&
      selectUserPreferences.photoLoading.loading === "idle"
    ) {
      loadUserPhoto(selectUserPreferences);
    }
    if (
      (!AssistantTypesState.entities ||
        AssistantTypesState.entities.length < 1) &&
      AssistantTypesState.loading === "idle"
    ) {
      //loadAssistantTypes();
      fetchAssistanTypes();
    }

    if (
      selectUserPreferences &&
      selectUserPreferences.uploadPhoto &&
      selectUserPreferences.uploadPhoto.response &&
      selectUserPreferences?.uploadPhoto.response.photoId
    ) {
      formRef.current?.change(
        "photoId",
        selectUserPreferences?.uploadPhoto.response.photoId
      );
      formRef.current?.change(
        "photo",
        selectUserPreferences?.uploadPhoto.response.photo
      );
    }

    return function cleanup() {
      // Side-effect cleanup...
      if (location.pathname !== window.location.pathname) {
        resetUserState(undefined);
      }
    };
  }, [
    selectUserPreferences,
    AssistantTypesState.entities,
    location.pathname,
    error,
    AssistantTypesState,
    buildingsPersonnelState.error,
  ]);

  const onSubmit = async (values: any) => {
    const dataToSave: UserPreferencesExtended = values;
    dataToSave.ucmnetid = ucmnetid;
    updateUserPreferences(dataToSave).then((_) => {
      setIsSaving(true);
      setTimeout(() => setIsSaving(false), 3000);
    });
  };

  const imageGuideDiv = () => {
    return (
      <React.Fragment>
        <div className="PhotoPreferences-edit-heading">
          <div className="PhotoPreferences-edit-heading-label">Photo</div>
          <a
            href="#"
            className="PhotoPreferences-edit-heading-link"
            onClick={(e) => {
              e.preventDefault();
              setIsVisibleImageGuide(!isVisibleImageGuide);
            }}
          >
            Image Guidelines
            <span className="PhotoPreferences-edit-heading-link-icon">▾</span>
          </a>
        </div>
        {isVisibleImageGuide && (
          // style={{display: isVisibleImageGuide ? '' : 'none!important'}}
          <div
            className={`PhotoPreferences-edit-imageGuidelines caption photoguide ${
              !isVisibleImageGuide ? "ng-hide" : ""
            }`}
          >
            <div>
              <div>
                <strong>Attire:</strong>{" "}
              </div>
              <div className="test">
                Professional, no plaids, busy prints, or non-UC Merced logos on
                clothing. No dark glasses or reflections on glasses that obscure
                the eyes.{" "}
              </div>
            </div>
            <div>
              <div>
                <strong>Orientation:</strong>
              </div>
              <div>
                Vertical photo, head, and shoulders. Ensure your face takes up
                at least 60% of the frame. Keep your head straight and upright
                while using a pleasant facial expression.
              </div>
            </div>
            <div>
              <div>
                <strong>Background:</strong>{" "}
              </div>
              <div>
                Keep it simple and out of focus (if possible) with no other
                people or distracting objects.
              </div>
            </div>
            <div>
              <div>
                <strong>Exposure:</strong>{" "}
              </div>
              <div>
                Properly exposed and well-lit (not too dark or too light, no
                harsh shadows). This is easiest achieved by taking a photo in
                open shade or soft, diffused light.
              </div>
            </div>
            <div>
              <div>
                <strong>Technical:</strong>
              </div>
              <div>
                Use a high-resolution image with a pixel count of 400 x 400 and
                a PNG or JPG file format.
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  const handleBookAppointment = (ucmnetid: string) => {
    requestPhotoHeadshot(ucmnetid);
  };

  const handleUploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let files: FileList | null = e?.target?.files;
    if (files && files.length > 0) {
      const photoUploadRequest: PhotoUploadRequest = {
        ucmnetid: ucmnetid as string,
        file: files.item(0) as File,
      };
      await uploadPhoto(photoUploadRequest);
    }
  };

  const formRef: React.MutableRefObject<FormApi | null> = useRef(null);

  type FieldInputProps = FieldRenderProps<string, HTMLElement>;

  const renderInput: React.FC<FieldInputProps> = (
    formProps: FieldInputProps
  ) => {
    const { input, label, ...rest } = formProps;
    return (
      <div className="InputLabelPair">
        <label className="InputLabelPair-label">{label}</label>
        <input
          {...input}
          type="text"
          autoComplete="off"
          {...rest}
          className="InputLabelPair-textInput"
        />
      </div>
    );
  };

  type Props = FieldRenderProps<string, HTMLElement>;

  const SelectInput: React.FC<Props> = ({
    input,
    label,
    meta,
    ...rest
  }: Props) => {
    return (
      <div className="InputLabelPair">
        <label className="InputLabelPair-label">{label}</label>
        <select className="InputLabelPair-selectInput" {...input} {...rest} />
      </div>
    );
  };
  const buildings = () =>
    buildingsPersonnelState.entities.map((building) => {
      return (
        <option key={building.code} value={building.code}>
          {building.name}{" "}
        </option>
      );
    });
  const rooms = (buildingCode: string) =>
    buildingsPersonnelState.entities
      .find((b) => b.code === buildingCode)
      ?.rooms.map((r) => {
        return (
          <option key={r.code} value={r.code}>
            {r.name}{" "}
          </option>
        );
      });

  const renderPhotoUploadErrors = (
    loading: "idle" | "pending" | "succeeded" | "failed",
    error?: ErrorToastr | null
  ) => {
    if (loading !== "failed") return null;
    const message = error
      ? error.message
      : "The image upload has been rejected. To ensure your image is accepted, please ensure it is a close " +
        "capture of your face and does not contain any of the following: Adult or racy content, Medical content, " +
        "Spoofed content, Violence, Multiple faces.";

    return <div className="PhotoPreferences-edit-error">{message}</div>;
  };

  return (
    <React.Fragment>
      <div className="AdminPreferencesLayout-personalInfo">
        <Link to="/" onClick={() => setShowDepartmentList(true)}>
          ← Directory Home
        </Link>
        {selectUserPreferences && loading === "succeeded" && (
          <div>
            <h1 className="PreferencesAdmin-heading">
              {selectUserPreferences.displayName}&#x27;s Personal Information
            </h1>
            <Link target="_blank" to={`/person/${ucmnetid}`}>
              Contact Permalink
            </Link>

            <NonEditPersonalInfo
              userPreferences={selectUserPreferences}
              backUrl={location.pathname}
            />
            {selectUserPreferences.mayEditLivedName && (
              <div className="livedName-box">
                <div
                  className={`livedName-split ${selectUserPreferences.livedName ? "livedName-split-3" : "livedName-split-2" }`}
                >
                  {selectUserPreferences.livedName && (
                    <div className="TableRow-label livedName-text">
                      Lived Name
                    </div>
                  )}
                  {selectUserPreferences.livedName && (
                    <div className="TableRow-value livedName-text">
                      {selectUserPreferences.livedName}
                    </div>
                  )}
                  {!selectUserPreferences.livedName && (
                    <div
                      ng-if="!$ctrl.user.livedName"
                      className="livedName-Label"
                    >
                      You can now set a lived name for the directory
                    </div>
                  )}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={selectUserPreferences.livedNameUrl}
                    className="Button--link Button Button--primary livedName-button"
                  >
                    Edit in {selectUserPreferences.livedNameLinkText}
                  </a>
                </div>
              </div>
            )}
            {selectUserPreferences.privateAccount && (
              <div className="privateAccount-box">
                <div>
                  Due to your account confidentiality settings, this account is
                  not displayed in the directory.
                </div>
              </div>
            )}
            <Form
              onSubmit={onSubmit}
              decorators={[focusOnErrors]}
              initialValues={selectUserPreferences}
              subscription={{ submitting: true, pristine: true, values: true }}
              mutators={{
                removeAssistant: (args, state, utils) => {
                  utils.changeValue(state, "assistants", (v: Assistant[]) => {
                    return v.filter((f) => {
                      const accountIdToRemove: string | undefined = args?.[0];
                      return f.accountId !== accountIdToRemove;
                    });
                  });
                },
                handleAddAssistant: (args, state, utils) => {
                  const assistantArg = { ...(args[0] as Assistant) };
                  assistantArg.bossId = "";

                  assistantArg.assistantType =
                    AssistantTypesState.entities[0].name;
                  assistantArg.typeCode = AssistantTypesState.entities[0].code;
                  assistantArg.ucmNetId = "";

                  utils.changeValue(state, "assistants", (v: Assistant[]) => {
                    return [...v, assistantArg];
                  });
                },
                ...arrayMutators,
              }}
              validate={(formValues) => {
                return {};
              }}
            >
              {({ handleSubmit, form, submitting, pristine }) => {
                formRef.current = form;

                return (
                    <form name="adminPreferences" onSubmit={handleSubmit}>
                      <div className="PhotoPreferences">
                        <Field name="photo">
                          {({input: {name, value}}) => {
                            let photoUrl = personAvatar;
                            if (selectUserPreferences?.uploadPhoto?.response?.photo) {
                              photoUrl = 'data:image/png;base64,' + selectUserPreferences?.uploadPhoto?.response?.photo;
                            } else if (value) {
                              photoUrl = value;
                            }
                            return (
                                <div
                                    className="PhotoPreferences-image"
                                    style={{backgroundImage: `url(${photoUrl})`}}
                                />
                            );
                          }}
                        </Field>
                        <Field name="photoId" component="input" type="hidden"/>
                        <div className="PhotoPreferences-edit">
                          <div>
                            {imageGuideDiv()}
                            <LoadingWheel
                                showLoading={
                                    selectUserPreferences.uploadPhoto.loading ===
                                    "pending"
                                }
                                numberOfDivs={3}
                                spinnerOnly={false}
                                classBlock="LoadingBlock-photo"
                                classSpinner="LoadingBlock-spinner LoadingBlock-spinner-photo"
                            />

                            {selectUserPreferences.uploadPhoto.loading !==
                                "pending" && (
                                    <div ng-if="!$ctrl.isUploadingPhoto">
                                      <label
                                          htmlFor="file"
                                          className="PhotoPreferences-edit-upload"
                                      >
                                        Choose File
                                      </label>
                                      <input
                                          type="file"
                                          id="file"
                                          accept=".jpg, .jpeg, .png,"
                                          className="PhotoPreferences-edit-upload-input"
                                          onChange={handleUploadPhoto}
                                          ng-change="$ctrl.uploadPhoto()"
                                          ng-model="$ctrl.photoSelected"
                                      />
                                    </div>
                                )}
                            {renderPhotoUploadErrors(
                                selectUserPreferences.uploadPhoto.loading,
                                selectUserPreferences.uploadPhoto.error
                            )}
                            <label className="Checkbox">
                              <Field
                                  name="displayPhoto"
                                  className="Checkbox-input"
                                  component="input"
                                  type="checkbox"
                              />
                              Display photo in the directory
                              {/* <input type="checkbox" className="Checkbox-input"  ng-model="$ctrl.user.displayPhoto" /> */}
                            </label>
                          </div>
                        </div>
                      </div>
                      {selectUserPreferences.mayRequestPhoto &&
                          (selectUserPreferences.photoHeadshotAppointment
                                  .loading === "idle" ||
                              selectUserPreferences.photoHeadshotAppointment
                                  .loading === "failed") &&
                          isVisiblePhotoBox && (
                              <div
                                  ng-show="$ctrl.user.mayRequestPhoto && !($ctrl.hideRequestPhotoBox || $ctrl.loadRequestPhoto)"
                                  className="headshot-box"
                              >
                                {selectUserPreferences.photoHeadshotAppointment
                                    .loading in ["succeeded"]
                                    ? "The university photographer has received your request. Please check your email for next steps. "
                                    : "Would you like to schedule a professional headshot with an on-campus photographer? "}
                                {!(
                                    selectUserPreferences.photoHeadshotAppointment
                                        .loading in ["succeeded"]
                                ) && (
                                    <a
                                        style={{cursor: "pointer"}}
                                        onClick={() =>
                                            handleBookAppointment(ucmnetid as string)
                                        }
                                    >
                                      Book an appointment
                                    </a>
                                )}
                                {selectUserPreferences.photoHeadshotAppointment
                                    .loading in ["succeeded"] && (
                                    <a
                                        onClick={() => setIsVisiblePhotoBox(false)}
                                        href="#"
                                    >
                                      Dismiss
                                    </a>
                                )}
                              </div>
                          )}
                      <LoadingWheel
                          numberOfDivs={3}
                          spinnerOnly={false}
                          showLoading={
                              selectUserPreferences.photoHeadshotAppointment
                                  .loading === "pending"
                          }
                          classBlock="LoadingBlock"
                          classSpinner="LoadingBlock-spinner LoadingBlock-spinner-photo"
                      />
                      <div className="InputLabelPair">
                        <label className="InputLabelPair-label" htmlFor="name-pronunciation">
                          Name Pronunciation
                        </label>
                        <Field
                            className="InputLabelPair-textInput"
                            component="input"
                            id="name-pronunciation"
                            name="namePronunciation"
                            placeholder="Matthew (pronounced 'Mah-thew')"
                            type="text"
                        />
                        <p className="caption">
                          This could be a phonetic pronunciation, or an example of something your name sounds like.
                        </p>
                      </div>

                      <div className="InputLabelPair">
                        <DisclosureProvider>
                          <div className="flex align-items-center mb-2">
                            <label htmlFor="about-section" className="InputLabelPair-label mb-0">
                              About
                            </label>
                            <DisclosureTrigger
                                appearance={ButtonType.Plain}
                                className="SummaryGuidelines ms-0"
                                closedIcon={faCaretDown}
                                iconPosition="right"
                                openIcon={faCaretUp}
                            >
                              Summary Guidelines
                            </DisclosureTrigger>
                          </div>

                          <DisclosureContent>
                            <AboutGuidelines/>
                          </DisclosureContent>

                          <Field name="about">
                            {props => (
                              <div>
                                <textarea
                                  {...props.input}
                                  className="InputLabelPair-textareaInput"
                                  id="about-section"
                                  maxLength={1024}
                                />
                                <div style={{ textAlign: 'right' }}>
                                  <CharacterCounter
                                    maxLength={1024}
                                    type={MessageType.Minimal}
                                    value={props.input.value}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </DisclosureProvider>
                      </div>

                      <section>
                        <div className="MultiInputRow MultiInputRow--3inputs">
                          <div>
                            <Field
                                name="workPhone"
                                label="Phone Number"
                                component={renderInput}
                            />
                          </div>
                          <div>
                            <Field
                                name="mobilePhone"
                                label="Mobile Number"
                                component={renderInput}
                            />
                          </div>
                          <div>
                            <Field
                                name="faxPhone"
                                label="Fax Number"
                                component={renderInput}
                            />
                          </div>
                        </div>
                        <div className="MyPreferences-assistant">
                          <FieldArray name="assistants">
                            {({fields}) => {

                              if (fields.length === 0) {
                                return <></>;
                              }

                              const assistantList = fields.map((assistant, index) => {
                                return (
                                    <div
                                        key={`${assistant}.accountId`}
                                        className="AssistantRow"
                                        ng-repeat="item in $ctrl.user.assistants"
                                    >
                                      <div className="AssistantRow-person">
                                        <div className="AssistantRow-person-name">
                                          <Field name={`${assistant}.name`}>
                                            {({input: {name, value}}) => value}
                                          </Field>
                                        </div>
                                        <div className="AssistantRow-person-title">
                                          <Field name={`${assistant}.title`}>
                                            {({input: {name, value}}) => value}
                                          </Field>
                                          {/* {`${assistant}.title`} */}
                                        </div>
                                      </div>
                                      <div className="AssistantRow-type">
                                        {AssistantTypesState.entities.map(
                                            (assistantType) => (
                                                <label
                                                    key={assistantType.code}
                                                    className="RadioButton"
                                                >
                                                  <Field
                                                      //key={assistantType.code}
                                                      name={`${assistant}.typeCode`}
                                                      component="input"
                                                      type="radio"
                                                      value={assistantType.code}
                                                      disabled={!isAdminPreferences}
                                                      //defaultValue={assistant.typeCode}
                                                  />
                                                  {assistantType.name}
                                                </label>
                                            )
                                        )}
                                      </div>
                                      {isAdminPreferences && <Field name={`${assistant}.accountId`}>
                                        {({input: {name, value}}) => (
                                            <a
                                                onClick={(e) => {
                                                  form.mutators.removeAssistant(value);
                                                }}
                                                className="AssistantRow-remove pointer"
                                            >
                                              Remove
                                            </a>
                                        )}
                                      </Field>}
                                    </div>
                                )
                              });

                              return (
                                  <>
                                    <div
                                        className="MyPreferences-assistant-label"
                                        ng-if="$ctrl.user.assistants && $ctrl.user.assistants.length > 0"
                                    >
                                      Assistant
                                    </div>
                                    {assistantList}
                                  </>
                              )
                            }
                            }
                          </FieldArray>
                          {!isAdminPreferences &&
                              <p className="caption">
                                Please contact your department admin to update assistants.
                              </p>
                          }
                          {isAdminPreferences && (
                              <AssistantSearch
                                  bossId={selectUserPreferences.accountId}
                                  callBackForAssistantSelectedToAdd={
                                    form.mutators.handleAddAssistant
                                  }
                              />
                          )}
                        </div>

                        <div className="InputLabelPair">
                          <FieldArray name="departments">
                            {({fields, meta}) => {
                              const fieldValues =
                                  fields.value as UserDepartments[];
                              return fields.map((department, index) => {
                                return (
                                    <fieldset key={`${department}.departmentCode`}>
                                      <legend>
                                        <Field
                                            name={`${department}.departmentTitle`}
                                        >
                                          {({input: {name, value}}) => value}
                                        </Field>
                                      </legend>

                                      {!fieldValues[index].primary && (
                                          <label className="Checkbox">
                                            <Field
                                                name={`${department}.displayed`}
                                                className="Checkbox-input"
                                                component="input"
                                                type="checkbox"
                                            />
                                            Display this departmental connection
                                          </label>
                                      )}

                                      <div className="InputLabelPair">
                                        <label
                                            className="InputLabelPair-label"
                                            htmlFor="working-title"
                                        >
                                          Working Title
                                        </label>
                                        <Field
                                            name={`${department}.workingTitle`}
                                            className="InputLabelPair-textInput"
                                            component="input"
                                            type="text"
                                            disabled={!isAdminPreferences}
                                        />
                                      </div>
                                      {!isAdminPreferences && (
                                          <p className="caption">
                                            Please contact your department admin to
                                            change the working title.
                                          </p>
                                      )}

                                      <div className="MultiInputRow MultiInputRow--2inputs">
                                        <FormSpy>
                                          {(props) => (
                                              <React.Fragment>
                                                <div>
                                                  <Field<string>
                                                      name={`${department}.buildingCode`}
                                                      label="Building"
                                                      component={SelectInput}
                                                  >
                                                    <option value="">
                                                      please choose one
                                                    </option>
                                                    {buildings()}
                                                  </Field>
                                                </div>
                                                <div>
                                                  <Field<string>
                                                      name={`${department}.roomCode`}
                                                      label="Room"
                                                      component={SelectInput}
                                                  >
                                                    <option value="">
                                                      please choose one
                                                    </option>
                                                    {rooms(
                                                        fieldValues[index].buildingCode
                                                    )}
                                                  </Field>
                                                </div>
                                              </React.Fragment>
                                          )}
                                        </FormSpy>
                                      </div>
                                    </fieldset>
                                );
                              });
                            }}
                          </FieldArray>
                        </div>

                        {authState.deptAdmin && (
                            <div className="PreferencesAdmin-actions">
                              <div className="PreferencesAdmin-actions-label">
                                Department Admin Actions
                              </div>
                              {selectUserPreferences.departments
                                  .filter((dept) => dept.primary)
                                  .map((department) => {
                                    return (
                                        <label
                                            key={department.departmentCode}
                                            className="Checkbox"
                                            ng-repeat="dept in $ctrl.user.departments | filter:{ primary: true }"
                                        >
                                          <Field
                                              className="Checkbox-input"
                                              name="deptAdmin"
                                              component="input"
                                              type="checkbox"
                                          />
                                          Has ({department.departmentTitle}) Admin
                                          Privileges
                                        </label>
                                    );
                                  })}
                              <label className="Checkbox">
                                <Field
                                    className="Checkbox-input"
                                    name="displayToPublic"
                                    component="input"
                                    type="checkbox"
                                />
                                Is visible in the public directory
                              </label>
                            </div>
                        )}

                        <div className="FormActions">
                          <button
                              className="Button Button--primary"
                              type="submit"
                              id="userPreferencesFormSubmit"
                              disabled={submitting}
                          >
                            Save Changes
                          </button>
                          <button
                              type="button"
                              className="Button Button--secondary"
                              onClick={form.reset}
                              disabled={submitting || pristine}
                          >
                            Discard Changes
                          </button>
                        </div>
                        <p className="caption">
                          Updates made to the directory will also reflect in
                          Microsoft Office 365 (Email, Calendar, Teams), Box,
                          Zoom, CatCourses, Connect, and other campus resources.
                        </p>
                      </section>
                    </form>
                );
              }}
            </Form>
          </div>
        )}
        <LoadingWheel
            numberOfDivs={4}
            spinnerOnly={false}
            showLoading={loading === "pending"}
        />
      </div>
      <UserAdminsOfDepartments
          selectUserPreferences={selectUserPreferences}
          ucmnetid={ucmnetid}
      />
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const {error, loading, entity, AssistantTypes} = state.userPreferences;
  return {
    error,
    loading,
    selectUserPreferences: entity,
    AssistantTypesState: AssistantTypes,
    buildingsPersonnelState: state.departments.buildingsPersonnel,
    authState: state.auth,
  };
};

const mapDispatch = {
  fetchUserPreferences,
  requestPhotoHeadshot,
  uploadPhoto,
  fetchPhoto,
  fetchAssistanTypes,
  fetchBuildingsPersonnel,
  updateUserPreferences,
  resetUserState,
  setIsDirty,
  setShowDepartmentList,
  setIsSaving,
  resetPhotoUpload,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(PersonalInfo);
