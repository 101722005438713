export const AboutGuidelines = () => (
    <div>
        <p>
            <strong>Why is a Directory About Summary Significant?</strong>
        </p>

        <p>
            Crafting a compelling About summary can significantly impact your interactions within the
            organization.
        </p>

        <p><strong>Here's why it matters:</strong></p>

        <ul>
            <li>
                <p>
                    <strong>Creates a Lasting First Impression:</strong> Your About Me summary serves as the initial
                    introduction for readers browsing your profile, making it a critical opportunity to make a
                    strong initial impact.
                </p>
            </li>
            <li>
                <p>
                    <strong>Facilitates Internal Collaboration:</strong> A well-written summary helps colleagues
                    understand your role, expertise, and interests, fostering better collaboration and networking
                    within the organization.
                </p>
            </li>
            <li>
                <p>
                    <strong>Showcases Your Personality:</strong> While your profile includes factual details like
                    your title, contact information, and department, your summary provides a platform to infuse
                    personality and narrative, allowing you to tell your story in a more engaging manner.
                </p>
            </li>
        </ul>

        <p><strong>Tips for Crafting Your About Summary:</strong></p>

        <ul>
            <li>
                <p>
                    <strong>Captivate with a Compelling Opening:</strong> Begin your profile summary with an
                    attention-grabbing statement or anecdote that reflects your passion, expertise, or professional
                    journey. This initial hook sets the tone for the rest of your summary and draws readers in,
                    encouraging them to continue reading.
                </p>
            </li>
            <li>
                <p>
                    <strong>Showcase Your Unique Value:</strong> Highlight what sets you apart. Focus on your key
                    strengths, accomplishments, and areas of expertise that demonstrate your value to potential
                    collaborators.
                </p>
            </li>
            <li>
                <p>
                    <strong>Keep it Concise and Relevant:</strong> While it's important to provide depth and detail,
                    strive to keep your summary concise and focused. Avoid unnecessary jargon or lengthy
                    descriptions. Instead, prioritize the most relevant information that aligns with your current
                    role and target audience. Aim for clarity and readability to ensure your message resonates
                    effectively.
                </p>
            </li>
        </ul>
    </div>
);
