import {FormEvent, useCallback, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {RootState} from "../../store";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import NonEditableStudentInfo from "../nonEditableStudentInfo/NonEditableStudentInfo";
import personAvatar from "../Content/images/defaultImage.png";
import {fetchPhoto, fetchStudentPreferences, updateStudentPreferences,} from "../../features/studentPersonalInfo";
import {StudentDetailsExtended} from "../../apis/dto/StudentDetails";
import {toastr} from "react-redux-toastr";
import {setIsSaving} from "../../features/globalStateReducer";

import "./StudentInfo.scss";
import {useApiController} from "../../hooks/useApiController";
import {StudentDetailsDTO, StudentPrefsControllerApi} from "@ucm-it/openapi-idm-js";
import {useApiUpdate} from "@ucm-it/openapi-helpers";
import {Button, ButtonType, Checkbox, CroppedImage, HideableText, Loader, TextField} from "@ucm-it/purrfect-components";
import {PageSection} from "../PageSection/PageSection";
import {PSKeyValue} from "../PageSection/PSKeyValue";

type Props = PropsFromRedux & {};

const StudentInfo = (props: Props) => {
  const { ucmnetid } = useParams();

  const studentPrefsApi = useApiController(StudentPrefsControllerApi);
  const [isStudentPrefsLoading, fetchStudentPrefs] = useApiUpdate(studentPrefsApi.getStudentDetails);
  const [isStudentPrefsSaving, saveStudentPrefs] = useApiUpdate(studentPrefsApi.saveStudentPreferences);

  const [studentPrefs, setStudentPrefs] = useState<StudentDetailsDTO | null>(null)

  useEffect(() => {
    if (ucmnetid && !isStudentPrefsLoading) {
      fetchStudentPrefs({ username: ucmnetid })
          .then(setStudentPrefs)
    }
  }, [ucmnetid]);

  const handleFieldChange = useCallback((key: string) => {
    return (value: any) => {
        setStudentPrefs((prev) => {
            if (prev) {
              return {
                  ...prev,
                  [key]: value,
              };
            }

            return prev;
        });
    };
  }, []);

  const handleOnSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();

    if (studentPrefs) {
      saveStudentPrefs({
        studentPreferencesDTO: {
          ucmnetid,
          ...studentPrefs
        }
      })
        .then(() => {
          toastr.success("Success", "Student preferences saved successfully");
        })
        .catch(() => {
          toastr.error("Error", "Failed to save student preferences");
        });
    }
  }, [studentPrefs, saveStudentPrefs, ucmnetid]);

  const {
    fetchStudentPreferences,
    fetchPhoto,
    error,
    loading,
    selectStudentPreferences,
  } = props;

  // @TODO: Deprecated code! Remove once the new UI is the only option
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }

    if (
      selectStudentPreferences &&
      selectStudentPreferences.photoLoading &&
      selectStudentPreferences.photoLoading.error
    ) {
      const { title, message } = selectStudentPreferences.photoLoading.error;
      toastr.error(title, message);
    }

    if (!selectStudentPreferences && loading === "idle" && ucmnetid) {
      fetchStudentPreferences(ucmnetid);
      //await fetchUserPreferences(ucmnetid);
    }
    if (
      selectStudentPreferences &&
      !selectStudentPreferences.photo &&
      selectStudentPreferences.photoLoading.loading === "idle"
    ) {
      fetchPhoto(selectStudentPreferences);
    }

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [selectStudentPreferences, error]);

  if (!studentPrefs || isStudentPrefsLoading) {
    return <Loader message="Fetching student information..." />
  }

  return (
    <div className="AdminPreferencesLayout-personalInfo">
      <Link to="/">← Back to Department Page</Link>

      <div>
        <div className="flex align-items-center my-4">
          <h1 className="text-h1 m-0">Personal Information</h1>

          <Link className="ms-3" target="_blank" to={`/person/${ucmnetid}`}>
            Contact Permalink
          </Link>
        </div>

        {studentPrefs.privateAccount && (
          <div className="privateAccount-box">
            <div>
              Due to your student confidentiality settings, this account is
              not displayed in the directory.
            </div>
          </div>
        )}

        {process.env.REACT_APP_NEW_PREF_UI === "true" ? (
          <form onSubmit={handleOnSubmit}>
            <PageSection title="Identity">
              <div className="row">
                <div className="col-md-3">
                  <CroppedImage
                      alt={`${studentPrefs.firstName}'s photo`}
                      src={studentPrefs.photoUrl}
                      keepAspectRatio
                      square
                      width="9em"
                  />
                  <Checkbox
                      className="mt-4"
                      label="Display photo in the directory"
                      onChange={() => {}}
                      value={studentPrefs.displayPhoto}
                  />
                </div>
                <div className="col-md-9">
                  <PSKeyValue
                      className="mb-4"
                      label="Merced ID (M.ID)"
                      value={studentPrefs.mid}
                      locked
                  />

                  <div className="row">
                    <div className="col-md-6">
                      <HideableText
                          className="mb-4"
                          label="Legal Name"
                          labelClassName="prefLabel mt-0"
                          text={[studentPrefs.lastName, studentPrefs.firstName].join(', ')}
                      />
                    </div>
                    <div className="col-md-6">
                      <PSKeyValue
                          className="mb-4"
                          label="Lived Name"
                          value={studentPrefs.livedName}
                          externalLink={studentPrefs.livedNameUrl}
                      />
                    </div>
                    <div className="col-md-6">
                      <PSKeyValue
                          className="mb-4 mb-md-0"
                          label="Pronouns"
                          value={studentPrefs.pronouns}
                          externalLink={studentPrefs.livedNameUrl}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                          label="Name Pronunciation"
                          labelClassName="prefLabel"
                          onChange={handleFieldChange('namePronunciation')}
                          value={studentPrefs.namePronunciation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PageSection>

            <PageSection title="Contact Information">
              <div className="row">
                <div className="col-md-6">
                  <PSKeyValue label="Email" value={studentPrefs.email} locked />
                </div>
                <div className="col-md-6">
                  <TextField
                      description={<em>This phone number will only be displayed in the Directory and is not intended for emergency communications.</em>}
                      label="Phone Number"
                      labelClassName="prefLabel"
                      onChange={handleFieldChange('studentPhone')}
                      value={studentPrefs.studentPhone}
                  />
                </div>
              </div>
            </PageSection>

            <PageSection title="Course of Study">
              <div className="row">
                <div className="col-md-6">
                  <PSKeyValue label="Major" locked value={[studentPrefs.major1, studentPrefs.major2].filter(Boolean).join(', ')} />
                  <Checkbox
                      className="mt-4"
                      label="Display my Major(s) in the directory"
                      onChange={handleFieldChange('displayMajors')}
                      value={studentPrefs.displayMajors}
                  />
                </div>
                <div className="col-md-6">
                  <PSKeyValue label="Minor" locked value={[
                    studentPrefs.major1minor1,
                    studentPrefs.major1minor2,
                    studentPrefs.major2minor1,
                    studentPrefs.major2minor2
                  ].filter(Boolean).join(', ')} />

                  <Checkbox
                      className="mt-4"
                      label="Display my Minor(s) in the directory"
                      onChange={handleFieldChange('displayMinors')}
                      value={studentPrefs.displayMinors}
                  />
                </div>
              </div>
            </PageSection>

            <div className="d-flex">
              <Button
                  appearance={ButtonType.Secondary}
                  isLoading={isStudentPrefsSaving}
                  type="button"
              >
                Discard Changes
              </Button>

              <Button
                  appearance={ButtonType.Primary}
                  className="ms-auto"
                  isLoading={isStudentPrefsSaving}
                  type="submit"
              >
                Save Changes
              </Button>
            </div>
          </form>
        ) : (
          <>
            <NonEditableStudentInfo
              studentPreferences={
                selectStudentPreferences as StudentDetailsExtended
              }
            />

            <div className="livedName-box">
              <div
                className={`livedName-split ${
                  studentPrefs.livedName ? "livedName-split-3" : ""
                }`}
              >
                {studentPrefs.livedName && (
                  <div className="TableRow-label livedName-text">Lived Name</div>
                )}
                {studentPrefs.livedName && (
                  <div className="TableRow-value livedName-text">
                    {studentPrefs.livedName}
                  </div>
                )}
                {!studentPrefs.livedName && (
                  <div className="livedName-Label">
                    You can now set a lived name for the directory
                  </div>
                )}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={studentPrefs.livedNameUrl}
                  className="Button--link Button Button--primary livedName-button"
                >
                  Edit in {studentPrefs.livedNameLinkText}
                </a>
              </div>
            </div>

            <form name="studentPreferences" onSubmit={handleOnSubmit}>
              <div className="PhotoPreferences">
                {selectStudentPreferences.photoLoading.loading !== "succeeded" && (
                  <div
                    className="PhotoPreferences-image"
                    style={{
                      backgroundImage: `url(${personAvatar})`,
                    }}
                  >
                    <LoadingWheel
                      numberOfDivs={4}
                      spinnerOnly={true}
                      showLoading={selectStudentPreferences.photoLoading.loading === "pending"}
                    />
                  </div>
                )}
                {selectStudentPreferences.photoLoading.loading === "succeeded" && (
                  <div
                    className="PhotoPreferences-image"
                    style={{backgroundImage: `url(${studentPrefs.photoUrl || personAvatar})`}}
                  ></div>
                )}
                <div className="PhotoPreferences-edit">
                  <div className="PhotoPreferences-edit-heading">
                    <div className="PhotoPreferences-edit-heading-label">
                      Photo
                    </div>
                  </div>
                  <Checkbox
                    label="Display photo in directory"
                    onChange={handleFieldChange('displayPhoto')}
                    value={studentPrefs.displayPhoto}
                  />
                </div>
              </div>

              <section>
                <div className="StudentPreferences-majorMinorOptions">
                  <h3 className="StudentPreferences-majorMinorOptions-label">
                    Course of Study
                  </h3>
                  <Checkbox
                    label="Display my Major(s) in the directory"
                    onChange={handleFieldChange('displayMajors')}
                    value={studentPrefs.displayMajors}
                  />
                  <Checkbox
                    label="Display my Minor(s) in the directory"
                    onChange={handleFieldChange('displayMinors')}
                    value={studentPrefs.displayMinors}
                  />
                </div>
                <div
                  className="InputLabelPair"
                  style={{ maxWidth: "20em" }}
                >
                  <TextField
                    description="Edit will only be reflected in the Directory"
                    label="Phone Number"
                    onChange={handleFieldChange('studentPhone')}
                    value={studentPrefs.studentPhone}
                    required
                  />
                </div>
                <div
                  className="InputLabelPair"
                  style={{ maxWidth: "20em" }}
                >
                  <TextField
                    label="Name Pronunciation"
                    onChange={handleFieldChange('namePronunciation')}
                    value={studentPrefs.namePronunciation}
                    placeholder="Matthew (pronounced 'Mah-thew')"
                  />
                </div>
                <div className="FormActions">
                  <button
                    className="Button Button--primary"
                    type="submit"
                    id="studentPreferencesFormSubmit"
                    disabled={isStudentPrefsSaving}
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    className="Button Button--secondary"
                    disabled={isStudentPrefsSaving}
                  >
                    Discard Changes
                  </button>
                </div>
              </section>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { error, loading, entity } = state.studentPersonalInfo;

  return {
    error,
    loading,
    selectStudentPreferences: entity,
  };
};

const mapDispatch = {
  fetchStudentPreferences,
  fetchPhoto,
  updateStudentPreferences,
  setIsSaving,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(StudentInfo);
