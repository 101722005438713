import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useEffect } from "react";
import {usePageTitle} from "../../hooks/usePageTitle";

interface Props {
  component: React.ComponentType;
  path?: string;
  isPrivate?: boolean;
  title?: string;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  title,
  isPrivate,
}) => {
  const authState = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle(title);
  }, [title]);

  if (isPrivate === undefined || !isPrivate) {
    return <RouteComponent />;
  } else if (authState.loggedIn) {
    return <RouteComponent />;
  } else if (!authState.loggedIn && authState.loading === "succeeded") {
    return (
      <Navigate
        to={
          (window.location.href = `${
            process.env.REACT_APP_SIGN_IN_URL as string
          }${location.pathname}`)
        }
      />
    );
  } else {
    return null;
  }
};
