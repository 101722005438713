import {useCallback} from "react";

export function usePageTitle() {
    const setPageTitle = useCallback((title: string = "") => {
        if (title) {
            document.title = `Directory: ${title}`;
        } else {
            document.title = "Directory";
        }
    }, []);

    return {
        setPageTitle,
    }
}
