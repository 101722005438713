import { connect, ConnectedProps } from "react-redux";

import { StudentDetailsExtended } from "../../apis/dto/StudentDetails";
import { useState } from "react";

type Props = PropsFromRedux & { studentPreferences: StudentDetailsExtended };

const NonEditableStudentInfo = (props: Props) => {
  const { studentPreferences } = props;

  const [isVisibleLegalName, setIsVisibleLegalName] = useState(false);

  const rowValues = (params: string[]) =>
    params.map((v) => {
      return (
        <div key={v} className="TableRow-value">
          {v}
        </div>
      );
    });

  const SimpleUserRow = (label: any, ...rest: any) => {
    return (
      <div className="TableRow TableRow-nonedit">
        <div className="TableRow-label">{label}</div>
        {rowValues(rest)}
      </div>
    );
  };

  const majors = (studentPreferences: StudentDetailsExtended) => {
    return [studentPreferences.major1, studentPreferences.major2]
      .filter(Boolean)
      .join(", ");
  };
  const minors = (studentPreferences: StudentDetailsExtended) => {
    return [
      studentPreferences.major1minor1,
      studentPreferences.major1minor2,
      studentPreferences.major2minor1,
      studentPreferences.major2minor2,
    ]
      .filter(Boolean)
      .join(", ");
  };

  const legalName =
    isVisibleLegalName &&
    (studentPreferences.lastName || studentPreferences.firstName)
      ? studentPreferences.lastName + ", " + studentPreferences.firstName
      : "xxxxxxxxxx";
  return (
    <div className="NonEditableInfoBox">
      <div className="NonEditableInfoBox-label">Non Editable Information</div>
      {SimpleUserRow("Legal Name", legalName)}
      {SimpleUserRow("Pronouns", studentPreferences.pronouns ?? <em>None Selected</em>)}
      {SimpleUserRow("Email", studentPreferences.email)}
      {studentPreferences.mid && SimpleUserRow("M.ID", studentPreferences.mid)}
      {studentPreferences.employeeId &&
        SimpleUserRow("Employee ID", studentPreferences.employeeId)}
      {SimpleUserRow("Major", majors(studentPreferences))}
      {SimpleUserRow("Minor", minors(studentPreferences))}

      <p className="caption">
        To correct the non editable information above, contact &nbsp;
        <a href="https://studentsfirst.ucmerced.edu/contact-us-0">
          Student First Center
        </a>
      </p>
      <p>
        <label className="Checkbox">
          <input
            type="checkbox"
            name="displayLegalName"
            className="Checkbox-input"
            onClick={() => setIsVisibleLegalName(!isVisibleLegalName)}
          />
          Show Legal Name
        </label>
      </p>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapDispatch = {};

const connector = connect(undefined, mapDispatch);

export default connector(NonEditableStudentInfo);
